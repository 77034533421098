import initialState from '../initialState.json';
import {
    ADD_EDIT_ACTIVITY_SUCCESS,
    GET_ACTIVITY_BY_ID_SUCCESS,
    GET_EVENT_FEED_SUCCESS,
    SORT_MY_ACTIVITIES_SUCCESS,
    GET_EVENT_FEED_FAILURE,
    SORT_MY_ACTIVITIES_FAILURE,
    GET_EVENT_FEED_LIST_SUCCESS
} from "../actions/Activity.actions";


export default function (
    state = initialState,
    action
) {
    switch (action.type) {

        case GET_ACTIVITY_BY_ID_SUCCESS: {
            return {
                ...state,
                activity: {
                    ...action.payload
                },
            }
        }
        case ADD_EDIT_ACTIVITY_SUCCESS: {
            return {
                ...state,
                activity: {
                    ...action.payload
                },
            }
        }
        case SORT_MY_ACTIVITIES_SUCCESS: {
          return {
            ...state,
            toDoList: {
              ...action.payload
            },
          }
        }
        case SORT_MY_ACTIVITIES_FAILURE: {
          return {
            ...state,
            toDoList: {
              ...action.payload
            },
          }
        }
        case GET_EVENT_FEED_SUCCESS: {
          return {
            ...state,
            eventFeed: {...action.payload},
          }
        }

        case GET_EVENT_FEED_LIST_SUCCESS: {
            return {
                ...state,
                eventFeedList: {...action.payload},
            }
        }

        case GET_EVENT_FEED_FAILURE: {
            return {
                ...state,
                eventFeed: {
                    events: []
                }
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}