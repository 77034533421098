import React, { useState, useCallback, useMemo, useRef } from "react";
import "./AeroGrid.css";
import { Avatar, AvatarGroup, IconButton, Pagination, Whisper, Dropdown, Popover, Tooltip } from "rsuite";
import { dateFormat } from "../../../helpers/homepageHelper";
import { SolIcon } from "@solstice/sol-react";
import arrow from "../../../assets/arrow.svg";
import selectedArrow from "../../../assets/selectedArrow.svg";

const AeroGrid = (props) => {

  const {
    columns,
    rowData,
    handlePagination,
    total,
    activePage,
    limit,
    isEditable,
    handleSort,
    height
  } = props;

  const [sortType, setSortType] = useState('');
  const [isSortPopoverOpen, setIsSortPopoverOpen] = useState(false);
  const [columnValue, setColumnValue] = useState('');
  const [direction, setDirection] = useState('');

  const renderMenu = ({ onClose, left, top, className }, ref) => {
    const handleSelect = eventKey => {
      setSortType(eventKey.replaceAll(',', ''));
      setDirection(eventKey.split(',')[1]);
      if (handleSort) {

        handleSort(eventKey.split(',')[0], eventKey.split(',')[1]);
      }
      onClose();
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item style={{ color: '#01151D' }} eventKey={`${columnValue.value},+`}><SolIcon style={{ color: '#637177', marginRight: '5px', verticalAlign: 'bottom' }} icon='arrow_upward' />Sort ascending</Dropdown.Item>
          <Dropdown.Item style={{ color: '#01151D' }} eventKey={`${columnValue.value},-`}><SolIcon style={{ color: '#637177', marginRight: '5px', verticalAlign: 'bottom' }} icon='arrow_downward' />Sort descending</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };
  // let ref = useRef();
  return (
    <React.Fragment>
      <div style={{ "height": height || "80vh", "overflowY": "auto", overflowX: 'hidden' }}>
        <table className="tableContainer">
          <thead className={'tableHeaderContainer'}>
            <React.Fragment>
              <tr>
                {columns?.map((column, i) => (
                  <th className={i === 0 ? 'tableHeaderBorderRadiusLeft' : (i === (columns?.length - 1)) ? 'tableHeaderBorderRadiusRight' : ''}
                    key={i}>
                    <div className="sortableColumn">
                      <div>
                        {column.label}
                      </div>
                      {column.isSortable && <Whisper onOpen={() => {
                        setColumnValue(column);
                      }} placement="bottomStart" trigger="click" speaker={renderMenu}>
                        <div>
                          <div style={{
                            backgroundImage: sortType === `${column.value}+` ? `url(${selectedArrow})` : `url(${arrow})`,
                            width: '18px',
                            height: '10px',
                            cursor: 'pointer'
                          }}
                          />
                          <div style={{
                            backgroundImage: sortType === `${column.value}-` ? `url(${selectedArrow})` : `url(${arrow})`,
                            width: '18px',
                            height: '14px',
                            transform: 'rotate(180deg)',
                            backgroundPositionX: 'right',
                            cursor: 'pointer'
                          }}
                          />
                        </div>
                      </Whisper>}
                    </div>

                  </th>
                ))}
              </tr>
            </React.Fragment>
          </thead>
          <tbody>
            <tr className={'emptyRow'}>
              <td colSpan={columns?.length}>
                <div className={'tableNoDescription'}>
                </div>
              </td>
            </tr>
            {rowData?.map((item) => (<React.Fragment>
              <tr className={'tableDetailsRowData'}>
                {columns?.map((column, i) => (
                  column?.label?.toLowerCase()?.includes('team') ? <td>
                    <div className={'tableCell'}>
                      <div className={'avatarContainer'}>
                        {(item?.contacts || item?.jllContacts)?.filter((user, i) => i < 3)
                          .map((user, index) => (
                            user.userImage ? <Whisper placement={'bottom'} trigger="hover" speaker={<Tooltip
                              placement={'bottom'}>{user.name}</Tooltip>}>
                              <div
                                className={(index !== ((item?.contacts || item?.jllContacts)?.length - 1)) ? 'avatarImage smallAvatarImage' : 'avatarImageFixed smallAvatarImage'}
                                key={user.id}>
                                <img style={{ height: 'auto', width: '30px' }} src={user.userImage} alt={user.name} />
                              </div>
                            </Whisper> : <Whisper placement={'bottom'} trigger="hover"
                              speaker={<Tooltip placement={'bottom'}>{user.name}</Tooltip>}>
                              <div
                                className={(index !== 3) ? 'avatarImage smallAvatarImage' : 'avatarImageFixed smallAvatarImage'}>
                                {user.name[0]}
                                {user.name.split(' ')[1].charAt(0)}
                              </div>
                            </Whisper>
                          ))}
                        {(((item?.contacts || item?.jllContacts))?.length > 4) &&
                          <Whisper placement={'bottom'} speaker={<Tooltip placement={'bottom'}>test</Tooltip>}>
                            <div className={'avatarImageCount smallAvatarImage'} style={{ background: '#EAEFF1', color: '#637177' }}>
                              +{(item?.contacts || item?.jllContacts)?.length - 3}
                            </div>
                          </Whisper>}

                      </div>
                    </div>
                  </td> : column?.value?.toLowerCase()?.includes('date') ? <td>
                    <div className={i === 0 ? ((i === 0 && item?.description?.length <= 0) ?
                      "tableCell fontWeight500 tableCellBorderRadiusLeft tableCellBorderRadiusLeftBottom" : "tableCell fontWeight500 tableCellBorderRadiusLeft") :
                      ((i === columns?.length - 1) ? ((i === columns?.length - 1 && !item.subject && !item.description) ? "tableCell tableCellBorderRadiusRight tableCellBorderRadiusRightTop tableCellBorderRadiusRightBottom" : "tableCell tableCellBorderRadiusRight") : 'tableCell')}>
                      {item[column.value] ? dateFormat(item[column.value], 'll') : '-'}
                      {column.hasTooltip && <Whisper
                        trigger="hover"
                        placement={'top'}
                        controlId={`control-id-top`}
                        speaker={
                          <Tooltip>{column?.renderTooltip(item)}</Tooltip>
                        }
                      >
                        <SolIcon size="16" icon='info' className="tooltipIcon" />
                      </Whisper>}
                    </div>
                  </td> : column?.value?.toLowerCase()?.includes('edit') ? <td>
                    <div className={i === 0 ? ((i === 0 && item?.description?.length <= 0) ?
                      "tableCell fontWeight500 tableCellBorderRadiusLeft tableCellBorderRadiusLeftBottom" : "tableCell fontWeight500 tableCellBorderRadiusLeft") :
                      ((i === columns?.length - 1) ? ((i === columns?.length - 1 && !item.subject && !item.description) ? "tableCell editColumn tableCellBorderRadiusRight tableCellBorderRadiusRightTop tableCellBorderRadiusRightBottom" : "tableCell editColumn tableCellBorderRadiusRight") : 'tableCell')}>
                      {isEditable && <SolIcon size="16" icon='edit' className="gridEditIcon" />}
                    </div>
                  </td> :
                    <td key={i}>
                      <div
                        data={i}
                        style={column.style}
                        className={i === 0 ? ((i === 0 && !item.subject && !item.description) ?
                          "tableCell fontWeight500 tableCellBorderRadiusLeft tableCellBorderRadiusLeftBottom" : "tableCell fontWeight500 tableCellBorderRadiusLeft") :
                          ((i === columns?.length - 1) ? ((i === columns?.length - 1 && !item.subject && !item.description) ? "tableCell tableCellBorderRadiusRight tableCellBorderRadiusRightTop tableCellBorderRadiusRightBottom" : "tableCell tableCellBorderRadiusRight") : 'tableCell')}>
                        {column.subValue ? item[column.value][column.subValue] : item[column.value]}
                      </div>
                    </td>))}
              </tr>
              {<tr>
                <td className={'descriptionData'} colSpan={columns?.length}>
                  <div className={(item.description || item.subject) ? 'tableDescription' : 'tableNoDescription'}>
                    {item.subject}<br />
                    {item.description}
                  </div>
                </td>
              </tr>}
            </React.Fragment>))}
          </tbody>
        </table>
      </div>
      <div style={{ "margin-top": "5px", "display": "flex", "justify-content": "center" }}>
        <Pagination
          prev
          last
          next
          ellipsis
          maxButtons={5}
          first
          size="md"
          total={total}
          limit={limit}
          activePage={activePage}
          onChangePage={(page) => {
            handlePagination(page, columnValue?.value, direction);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default AeroGrid;