import React, {useCallback, useEffect, useState} from 'react';
import AeroModal from "../AeroModal/AeroModal";
import {Input, MaskedInput, Modal} from "rsuite";
import {Controller, useForm} from "react-hook-form";
import Autosuggest from "react-autosuggest";
import {autoSuggestScrollToElement} from "../../utils/helpers";
import {bindActionCreators} from "redux";
import * as ContactActions from "../../store/actions/Contact.actions";
import * as SearchActions from "../../store/actions/Search.actions";
import * as LookupActions from "../../store/actions/Lookup.actions";
import {connect} from "react-redux";
import {debounce} from "lodash";
import {setGA4Data} from "../../utils/googleAnalytics";
import './ModalCreateContact.css';

const ModalCreateContact = (props) => {
    const {search, contactActions, handleClose} = props;

    const {control, handleSubmit, getValues} = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            jobTitle: '',
            importantInfo: '',
            email: '',
            phone: '',
            company: '',
        },
    });
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [companyInfo, setCompanyInfo] = useState([]);
    const [company, setCompany] = useState('');
    const [companyId, setCompanyId] = useState('');
    const [initialCompanyLoad, setInitialCompanyLoad] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [errors, setErrors] = useState([]);
    const values = getValues();

    useEffect(() => {
        if(errors.length > 0) {
            const items = [
                ...!values.firstName ? ['firstName'] : [],
                ...!values.lastName ? ['lastName'] : [],
                ...!companyInfo.id ? ['company'] : [],
            ];
            setErrors(items);
        }

    }, [values]);

    useEffect(() => {
        if (initialCompanyLoad === false) {
            setCompanySuggestions(search);
        }
    }, [search]);

    useEffect(() => {
            !companyId && getCompaniesDebounced(company);
        },
        [company]);

    const getCompaniesDebounced = useCallback(
        debounce((company) => {
            props.searchActions.getSearchDetails({
                type: 'companies',
                searchText: company,
                pageNo: 1,
                offset: 0,
            })
            setInitialCompanyLoad(false);
        }, 300), []
    );

    const validateForm = () => {
        const values = getValues();

        if(!values.firstName || !values.lastName || !companyInfo.id) {
            const items = [
                ...!values.firstName ? ['firstName'] : [],
                ...!values.lastName ? ['lastName'] : [],
                ...!company.id ? ['company'] : [],
            ];
            setErrors(items);
            return false
        }
        return true;
    }
    const onSubmit = () => {
        const values = getValues();
        setErrors([]);
       if (validateForm()){
            const contactData = {
                addressLine1: companyInfo.addressLine1,
                addressLine2: companyInfo.addressLine2,
                city: companyInfo.city,
                companyId: companyInfo.id,
                contactableBy: "Anyone",
                countryId: 1296,
                email: values.email,
                firstName: values.firstName,
                importantInformation: values.importantInfo,
                jobFunctionId: null,
                lastName: values.lastName,
                mobilePhone: values.phone,
                officePhone: null,
                state: companyInfo.state,
                title: values.jobTitle,
                zipCode: companyInfo.zipCode,
                id: null
            }

            // if (activityText === 'Save') {
            //     setGA4Data('ContactModified', { companyID: regarding?.id });
            // } else {
            //     setGA4Data('ContactCreated', { companyID: regarding?.id });
            // }
            contactActions.addEditContact({contactData, cb: (flag) =>  {
                    setShowLoader(flag);
                    if(!flag) {
                        handleClose();
                    }
                }});
        }


    }


    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
            {errors.includes('company') && <div className={'requiredStar'}>Required</div>}
        </div>
    );


    const onCompanySuggestionSelected = (event, {suggestion}) => {
        event.preventDefault();
        setCompany(suggestion.companyName);
        setCompanyId(suggestion.id);
        setCompanyInfo(suggestion);
        onCompanySuggestionsClearRequested();
    };
    const onCompanySuggestionsClearRequested = () => {
        setCompanySuggestions([]);
    };
    const onCompanySuggestionsFetchRequested = () => {
        return companySuggestions;
    };
    const onCompanyChange = (val) => {
        debugger;
        setCompany(val.target?.value);
        setCompanyId(null);
        if(!val.target?.value) {
            setCompanyInfo({});
        }
    };
    const getCompanySuggestionValue = suggestion => suggestion.description;
    const renderCompanySuggestion = suggestion => (
        <div>
            {suggestion.companyName}
        </div>
    );

    const renderCompanyField = () => {
        return (<>
            <label className='frmLabel' id="company">Company <span className='requiredStar'>*</span></label>
            <Autosuggest
                required
                theme={{
                    input: "auto-suggest-input",
                    suggestionsContainer: 'auto-suggest-container',
                    suggestion: 'auto-suggest-suggestion',
                    suggestionHighlighted: 'suggestion-highlighted'
                }}
                style={{border: '1px solid green !important'}}
                suggestions={companySuggestions || []}
                onSuggestionsFetchRequested={onCompanySuggestionsFetchRequested}
                onSuggestionsClearRequested={onCompanySuggestionsClearRequested}
                getSuggestionValue={getCompanySuggestionValue}
                renderSuggestion={renderCompanySuggestion}
                onSuggestionSelected={onCompanySuggestionSelected}
                renderInputComponent={renderInputComponent}
                inputProps={{
                    id: 'companyInput',
                    value: company || '',
                    onChange: onCompanyChange,
                    onClick: () => {
                        autoSuggestScrollToElement('company');
                    }
                }}
                blurOnSelect
                class="selectInput"
                className="selectInput"
            />
        </>)
    }

    const renderInputField = (name, label, as, required, fieldClassName) => {
        return <div style={{width:'100%'}}>
            <label className={'frmLabel'}>
                {label}{required && <span className="requiredStar">*</span>}
            </label>
            <Controller
                control={control}
                render={({field}) => <Input {...field} as={as} rows={4} className={`aeroInputField ${fieldClassName}`}/>}
                name={name}
            />
            {errors.includes(name) && <div className={'requiredStar'}>Required</div>}
        </div>
    }


    const renderMaskField = (name, label, regex, required) => {
        return <div>
            <label>
                <label className='frmLabel'>{label} {required && <span className='requiredStar'>*</span>}</label>
            </label>
            <Controller
                control={control}
                render={({field}) => <MaskedInput mask={regex} {...field} className={'aeroInputField'}/>}
                name={name}
            />
        </div>
    }

    const {open} = props;

    const closeModal = () => {
        props.handleClose();
    }


    return (
        <div>
            <AeroModal
                size={500}
                title={'Create Contact'}
                handleClose={() => closeModal()}
                isOpen={open}
                primaryButtonText={'Create'}
                secondaryButtonText={'Cancel'}
                handlePrimaryButtonClick={() => onSubmit()}
                handleSecondaryButtonClick={() => closeModal()}
            >
                <div className="CreateContact">
                    <form>
                        <div className={'multipleInputFiledContainer'}>
                            {renderInputField('firstName', 'First name', 'input', true, )}
                            <div className='secondaryInputField' >
                            {renderInputField('lastName', 'Last name', 'input', true, )}
                            </div>
                        </div>
                        <div className={'singleInputFiledContainer'}>
                            {renderInputField('email', 'Email')}
                        </div>
                        <div className={'singleInputFiledContainer'}>
                            {renderCompanyField()}
                        </div>


                        <div className={'multipleInputFiledContainer'}>
                            {renderInputField('jobTitle', 'Job title')}
                            <div className={'secondaryInputField'}>
                            {renderMaskField('phone', 'Phone', ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],)}
                            </div>
                        </div>
                        <div className={'singleInputFiledContainer'}>
                            {renderInputField('importantInfo', 'Important information', 'textarea')}
                        </div>
                    </form>
                </div>

            </AeroModal>

        </div>
    )
};

function mapStateToProps(state) {
    return {
        contact: state.contact.contactDetails,
        search: state.search.searchResults.companiesResults,
        countries: state.lookup.lookup,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        contactActions: bindActionCreators(ContactActions, dispatch),
        searchActions: bindActionCreators(SearchActions, dispatch),
        lookupActions: bindActionCreators(LookupActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalCreateContact);
