import React from 'react';
import './EventFeedContainer.css';
import HomePageComponentDesktop from "../HomePageComponent/HomePageComponentDesktop";
import {useSelector} from "react-redux";
import EventFeedCard from "../EventFeedCard/EventFeedCard";
import {Placeholder} from 'rsuite';
import emptyEventFeed from "../../../assets/emptyEventFeed.svg";
import EventFeedGrid from "../EventFeedGrid/EventFeedGrid";

const EventFeedContainer = (props) => {
    const eventFeed = useSelector(state => state.activity.eventFeed.events);
    const [isGridOpen, setIsGridOpen] = React.useState(false);
    const { activityActions } = props;
    return (
        <div className='eventFeedCardContainer'>
            <HomePageComponentDesktop
                header="Event feed"
                data={eventFeed}
                containerStyles={{marginRight: '0px !important'}}
                bodyClassName={'eventFeedBody'}
                handleOnClick={() => setIsGridOpen(true)}
            >
                {eventFeed?.length > 0 ? eventFeed?.map(eventItem => {
                    return <EventFeedCard feedDetails={eventItem}/>
                }) : eventFeed && <div className={'emptyEventFeedContainer'}>
                    <img style={{paddingBottom: '35px'}} src={emptyEventFeed}/>
                    <br/>
                    <text >Contact us to update your subscription to follow teams and companies that matter to you.</text>
                </div>}
                {!eventFeed &&
                    <div style={{minHeight: '60vh', paddingTop: '20px'}}>
                        <Placeholder.Paragraph graph="square" active/>
                    </div>}
            </HomePageComponentDesktop>
            {isGridOpen && <EventFeedGrid
                activityActions={activityActions}
                isOpen={isGridOpen}
                onClose={() => setIsGridOpen(false)}
            />}
        </div>
    )
}

export default EventFeedContainer;