import initialState from '../initialState.json';
import {GET_FUZZY_SEARCH_RESULTS, GET_FUZZY_SEARCH_RESULTS_SUCCESS} from "../actions/FuzzySearch.actions";


export default function (
  state = initialState,
  action
) {
  switch (action.type) {
    
    case GET_FUZZY_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: [{ id: -1, fullName: 'Loading...' }],
        currentType: action.payload.type,
      };

    case GET_FUZZY_SEARCH_RESULTS_SUCCESS:
      if (state.currentType !== action.payload.type) {
        if (
          (action.payload.elements && action.payload.elements.length <= 0) ||
          (action.payload.results && action.payload.results.length === 0) ||
          action.payload.length <= 0
        ) {
          return {
            ...state,
            searchResults: [
              {
                id: -2,
                fullName: 'No results found',
              },
            ],
          };
        }
        return {
          ...state,
          searchResults: action.payload.elements || action.payload,
        };
      }
      return {
        ...state,
        searchResults: [
          { id: -1, fullName: 'Loading...', currentType: action.payload.type },
        ],
      };

    default:
      return state;
  }
};
