import React, { useCallback, useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { debounce, isEmpty } from "lodash";
import { getBlogEntries, getBlogOwners } from "../../../store/actions/Echelon.actions";
import { useDispatch, useSelector } from "react-redux";
import './SearchContainer.css';
import { lookup } from "../../../store/actions/Lookup.actions";
import { SolButton, SolIcon } from "@solstice/sol-react";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";

const SearchContainerDesktop = (props) => {
  const [blogTextFilter, setBlogTextFilter] = useState('');
  const [blogDateFilter, setBlogDateFilter] = useState('');
  const [ownerFilter, setOwnerFilter] = useState('');
  const [ownerData, setOwnerData] = useState({});
  const [submarketFilter, setSubMarketFilter] = useState([]);
  const [ownerSuggestions, setOwnerSuggestions] = useState([]);
  const [tim, setTim] = useState(null);
  const [jllOnly, setJllOnly] = useState(null);
  const dispatch = useDispatch();
  const blogOwners = useSelector(state => state.echelon.blogOwners);
  const submarkets = useSelector(state => state.lookup.lookup);
  const resetSearchFilters = useSelector(state => state.echelon.resetSearchFilters);

  const [submarketSuggestions, setSubmarketSuggestions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activeItem, setActiveItem] = useState('null');


  React.useEffect(() => {
    dispatch(lookup('GetBlogSubMarkets', { returnAll: 1 }));
  }, []);

  React.useEffect(() => {
    if (resetSearchFilters) {
      resetFilters();
    }
  }, [resetSearchFilters]);

  React.useEffect(() => {
    let tempSubmarkets = [];
    tempSubmarkets = submarkets.map(item => {
      return { label: item.value, value: item.id }
    });
    setSubmarketSuggestions(tempSubmarkets)
  }, [submarkets])

  useEffect(() => {
    if (!isEmpty(blogOwners)) {
      if (blogOwners.length === 1 && ownerSuggestions.length === 0 && ownerFilter === blogOwners[0].userName) {
        //Do Nothing
      } else {
        setOwnerSuggestions(blogOwners);
      }
    }
  }, [blogOwners]);

  useEffect(() => {
    if ((endDate && startDate) || (!endDate && !startDate)) {
      handleSearch(ownerData.userName, ownerData.userId);
    }
  }, [tim, jllOnly, startDate, endDate, submarketFilter]);

  const getUsersDebounced = useCallback(
    debounce((ownerValue, usersActions) => {
      dispatch(getBlogOwners({ value: ownerValue }))
    }, 300), []
  )

  React.useEffect(() => {
    const { usersActions } = props
    if (!isEmpty(ownerFilter) && ownerFilter.length >= 3) {
      getUsersDebounced(ownerFilter, usersActions);
    }

  }, [ownerFilter]);


  const handleSearch = (ownerName, ownerId) => {
    const { setSearchParams } = props;
    const submarketValue = submarketFilter.map(item => parseInt(item.value));

    const searchData = {
      TIM: tim,
      JLLOnly: jllOnly,
      BlogText: blogTextFilter,
      CreatedBy: ownerId || null,
      CreatedByName: ownerId ? null : ownerName,
      StartDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      EndDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      PageSize: 50,
      PageNo: 1,
      SubmarketIds: submarketValue
    };

    setSearchParams(searchData);

    dispatch(getBlogEntries({
      searchData: searchData,
      newSearch: true

    }));
  }

  const resetFilters = () => {
    setBlogTextFilter("");
    setSubMarketFilter([]);
    setOwnerFilter("");
    setStartDate('');
    setEndDate('');
    setJllOnly(null);
    setTim(null);
    setOwnerData({});
  }
  const getOwnerSuggestions = value => {
    if (!isEmpty(value) && value.length >= 3) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 && !isEmpty(ownerSuggestions) ? [] : ownerSuggestions.filter(o => {
        return o.userName.toLowerCase().slice(0, inputLength) === inputValue
      });
    } else {
      return [];
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onOwnerChange = (event, { newValue }) => {
    setOwnerFilter(newValue);
  };
  const onOwnerSuggestionsFetchRequested = ({ value }) => {
    const suggestions = getOwnerSuggestions(value);
    setOwnerSuggestions(suggestions);
  };
  const onOwnerSuggestionsClearRequested = () => {
    const suggestions = getOwnerSuggestions([]);
    setOwnerSuggestions(suggestions);
  };
  const onOwnerSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
    event.preventDefault();
    setOwnerFilter(suggestion.userName);
    setOwnerData({ userName: suggestion.userName, userId: suggestion.userId });
    handleSearch(suggestion.userName, suggestion.userId);
    onOwnerSuggestionsClearRequested();
  }

  const getOwnerSuggestionValue = suggestion => suggestion.userName;
  const renderOwnerSuggestion = suggestion => (
    <div className="dvSuggest">
      {suggestion.userName}
    </div>
  );
  const inputProps = {
    placeholder: 'Select Owner',
    value: ownerFilter,
    onChange: onOwnerChange,
  };

  // console.log(submarketSuggestions?.filter(item => submarketFilter?.includes(item.value) ? item.label : ''), 'VALS');

  const renderInputComponent = inputProps => (
    <div>
      <input {...inputProps} />
      <SolIcon
        size="20"
        style={{ display: !ownerFilter && 'none' }}
        className='xIcon'
        icon="close"
        onClick={async () => {
          await setOwnerFilter('');
          setOwnerData({});
          handleSearch('');
        }}
      />
    </div>
  );

  return (
    <div className={'filterContainer'}>
      <div className='searchFieldSection'>
        <label htmlFor="owner" id="owner" className='searchLabel'>
          Blog Text
          <SolButton
            label={'Clear All'}
            variant={'secondary'}
            onSol-click={() => {
              resetFilters();
            }}
            size={'medium'}
          >
          Clear All
          </SolButton>
        </label>
        <div style={{ position: 'relative' }}>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSearch(ownerData.userName, ownerData.userId);
          }}>
            <input
              className={'blogSearchInput inputTextField'}
              value={blogTextFilter}
              onChange={e => {
                setBlogTextFilter(e.target.value)
              }}
            />
            <SolIcon
              onClick={() => {
                handleSearch(ownerData.userName, ownerData.userId);
              }}
              className='searchIcon'
              icon="search"
              size="20"
            />
          </form>
        </div>
      </div>
      <div>
        <div><label className='frmLabel'>Submarket</label></div>
        <MultiSelect
          options={submarketSuggestions}
          value={submarketFilter}
          onChange={async (val) => {
            await setSubMarketFilter(val);
          }}
          labelledBy="Select"
        />
      </div>
      <div className='searchFieldSection'
        onClick={() => {
          setActiveItem('owner');
        }}><label htmlFor="owner" id="owner" className='searchLabel'>Owner</label>
        <Autosuggest
          theme={{
            input: ownerFilter ? "auto-suggest-owner-input-padding inputTextField" : "auto-suggest-owner-search-input inputTextField",
            suggestionsContainer: 'echelon-auto-suggest-search-container',
            suggestion: 'auto-suggest-suggestion-echelon',
            suggestionHighlighted: 'suggestion-highlighted'
          }}
          renderInputComponent={renderInputComponent}
          style={{ border: '1px solid green !important' }}
          suggestions={ownerSuggestions}
          onSuggestionsFetchRequested={onOwnerSuggestionsFetchRequested}
          onSuggestionsClearRequested={onOwnerSuggestionsClearRequested}
          getSuggestionValue={getOwnerSuggestionValue}
          renderSuggestion={renderOwnerSuggestion}
          onSuggestionSelected={onOwnerSuggestionSelected}
          inputProps={inputProps}
          blurOnSelect
          class="selectInput"
          className="selectInput"
        />
      </div>
      <div className='searchFieldSection'
        onClick={() => {
          setActiveItem('blogDate');
        }}
      >
        <label htmlFor="owner" id="owner" className='searchLabel'>Blog Date</label>
        <DatePicker
          onClick={() => {
            setActiveItem('blogDate');
          }}
          className={'datePicker'}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          placeholderText="Select Date Range"
          selectsRange
          showYearDropdown
          isClearable={startDate && endDate ? true : false}
          returnValue="range"
          dateFormat="MM/dd/yyyy"
          selected={blogDateFilter}
        />
      </div>
      <div className='pillsContainer'>
        <div
          role={"button"}
          onClick={() => setJllOnly(jllOnly ? null : true)}
          className={jllOnly ? 'selectedPill' : 'unselectedPill'}
        >
          JLL Only
        </div>
        <div
          role={"button"}
          onClick={() => setTim(tim ? null : true)}
          className={tim ? 'timPillSelected' : 'unselectedPill'}
        >
          TIM
        </div>
      </div>
    </div>
  )
}

export default SearchContainerDesktop;