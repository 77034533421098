import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import searchReducer from './Search.reducer'
import companyReducer from "./Company.reducer";
import usersReducer from "./Users.reducer";
import activityReducer from "./Activity.reducer";
import contactReducer from "./Contact.reducer";
import LookupReducer from './Lookup.reducer';
import EchelonReducer from "./Echelon.reducer";
import LeaseReducer from "./Lease.reducer";
import FuzzySearchReducer from "./FuzzySearch.reducer";

export default combineReducers({
    routing: routerReducer,
    search: searchReducer,
    company: companyReducer,
    users: usersReducer,
    activity: activityReducer,
    contact: contactReducer,
    lookup: LookupReducer,
    echelon: EchelonReducer,
    lease: LeaseReducer,
    fuzzySearch: FuzzySearchReducer
});
