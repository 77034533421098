import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    GET_EXPIRING_LEASES, getExpiringLeasesFailure,
    getExpiringLeasesSuccess,
} from "../actions/Lease.actions";

export function* getExpiringLeases(action) {
    try {
        const response = yield call(
            api.leaseApi.getExpiringLeases,
            action.payload
        );
        yield put(getExpiringLeasesSuccess(response));
    } catch (e) {
        yield put(getExpiringLeasesFailure({}));
        console.log(e);
    }
}

export function* watchGetExpiringLeases() {
    yield takeEvery(GET_EXPIRING_LEASES, getExpiringLeases);
}

export default function* usersSagas() {
    yield all([
        watchGetExpiringLeases(),
    ]);
}