export const GET_FUZZY_SEARCH_RESULTS = "GET_FUZZY_SEARCH_RESULTS";
export const GET_FUZZY_SEARCH_RESULTS_SUCCESS = "GET_FUZZY_SEARCH_RESULTS_SUCCESS";

export function getFuzzySearchResults(data) {
    return {
        payload: data,
        type: GET_FUZZY_SEARCH_RESULTS,
    };
}

export function getFuzzySearchResultsSuccess(data) {
    return {
        payload: data,
        type: GET_FUZZY_SEARCH_RESULTS_SUCCESS,
    };
}