import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  GET_FUZZY_SEARCH_RESULTS,
    getFuzzySearchResultsSuccess,
} from "../actions/FuzzySearch.actions";

export function* getFuzzySearchResultsSaga(action) {
    try {
        const response = yield call(
            api.fuzzySearchApi.getFuzzySearchResults,
            action.payload
        );
        yield put(getFuzzySearchResultsSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetFuzzySearchResults() {
    yield takeEvery(GET_FUZZY_SEARCH_RESULTS, getFuzzySearchResultsSaga);
}

export default function* usersSagas() {
    yield all([
        watchGetFuzzySearchResults(),
    ]);
}