import React from 'react';
import './LeaseExpirationsGrid.css';
import { Drawer, Input, InputGroup } from "rsuite";
import { SolIcon } from "@solstice/sol-react";
import AeroGrid from "../AeroGrid/AeroGrid";
import * as LeaseActions from "../../../store/actions/Lease.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LeaseExpirationGrid = (props) => {

  const { isOpen, onClose, data, leaseActions } = props;
  const [activePage, setActivePage] = React.useState(1);
  const [columnFilter, setColumnFilter] = React.useState('');
  const [directionFilter, setDirectionFilter] = React.useState('');
  const [startDate, setStartDate] = React.useState(moment().format('L'));
  const [endDate, setEndDate] = React.useState(moment().add(4, 'years').format('L'));
  const [dateChanged, setDateChanged] = React.useState(false);

  const handlePagination = (page, column, direction) => {
    setActivePage(page);
    leaseActions.getExpiringLeases({
      limit: 10,
      offset: (page - 1) * 10,
      sortBy: `${direction}${column === 'clientName' ? 'client' : column === 'startDate' ? 'start' : ''}`,
      startDate: moment(startDate).format('L'),
      endDate: moment(endDate).format('L'),
    });
  }

  const handleSort = (column, direction) => {
    setActivePage(1);
    setColumnFilter(column);
    setDirectionFilter(direction);
    handlePagination(1, column, direction);
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setDateChanged(true);
  };

  React.useEffect(() => {
    dateChanged && endDate && handlePagination(1, columnFilter, directionFilter);
  }, [endDate]);

  let expiringLeasesGridColumns = [{
    id: 1,
    label: 'Company',
    value: 'clientName',
    isSortable: true,
  },
  {
    id: 2,
    label: 'Address',
    value: 'address',
  },
  {
    id: 3,
    label: 'Start',
    value: 'startDate',
    isSortable: true,
  },
  {
    id: 4,
    label: 'Expiration',
    value: 'endDate',
  },];

  return (
    <div className='leaseExpirationGridContainer' >
      <Drawer className='drawerPanel' size={'md'} open={isOpen}>
        <div className='drawerContainer'>
          <div className='drawerHeaderContainer'>
            <div className='drawerHeader'>Lease expirations</div>
            <SolIcon icon='close' className='drawerCloseIcon' onClick={() => onClose()} />
          </div>
          <div className='leaseExpirationDatepickerContainer'>
            <div style={{ marginBottom: '10px' }}>
              <label className='leaseExpirationDatepickerLabel'>Start Date</label>
              <label className='leaseExpirationDatepickerLabel' style={{ marginLeft: '215px' }}>End Date</label>
            </div>
            <DatePicker
              className={'leaseExpirationDatepicker'}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Select Date Range"
              selectsRange
              showYearDropdown
              returnValue="range"
              dateFormat="MM/dd/yyyy"
              customInput={
                <span id='a' className="leaseExpirationDatepickerInput">
                  <InputGroup inside>
                    <Input
                      className='leaseExpirationDatepickerColor'
                      value={startDate ? moment(startDate).format('MMM DD, YYYY') : ''}
                      placeholder="Select Start Date"
                    />
                    <InputGroup.Button>
                      <SolIcon icon='calendar_month' className='leaseExpirationDatepickerColor' />
                    </InputGroup.Button>
                  </InputGroup>
                  <InputGroup inside>
                    <Input
                      className='leaseExpirationDatepickerColor'
                      value={endDate ? moment(endDate).format('MMM DD, YYYY') : ''}
                      placeholder="Select End Date"
                    />
                    <InputGroup.Button>
                      <SolIcon icon='calendar_month' className='leaseExpirationDatepickerColor' />
                    </InputGroup.Button>
                  </InputGroup>
                </span>
              }
            />
          </div>
          <AeroGrid
            height='60vh'
            columns={expiringLeasesGridColumns}
            rowData={data?.expiringLeases}
            handlePagination={handlePagination}
            total={data?.totalCount}
            limit={10}
            activePage={activePage}
            handleSort={handleSort}
          />
        </div>
      </Drawer>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    lease: state.lease,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    leaseActions: bindActionCreators(LeaseActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaseExpirationGrid);