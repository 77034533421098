import * as activityApi from "./activity.api"
import * as searchApi from "./search.api"
import * as companyApi from "./company.api"
import * as usersApi from "./users.api"
import * as contactApi from "./contact.api"
import * as lookupApi from "./lookup.api"
import * as echelonApi from "./echelon.api"
import * as leaseApi from "./lease.api"
import * as fuzzySearchApi from "./fuzzySearch.api"

export default {
    activityApi,
    searchApi,
    companyApi,
    usersApi,
    contactApi,
    lookupApi,
    echelonApi,
    leaseApi,
    fuzzySearchApi,
};
