import React from 'react';
import { SolButton } from "@solstice/sol-react";
import './HomePageComponent.css';
import classnames from 'classnames';

const HomePageComponentDesktop = ({ children, ...props }) => {
  const { data } = props;
  return (
    <div className={'HomePageComponent'} id='HomePageComponent' style={props.containerStyles}>
      <div className='HomePageComponentHeader'>
        <p1>{props.header}</p1>
        {data?.length > 0 &&
          <SolButton
            label={'View more'}
            variant={'outlined'}
            size={'medium'}
            onSol-click={() => {
              props.handleOnClick();
            }}
          >
            View more
          </SolButton>}
      </div>
      <div className={classnames('body', props.bodyClassName)} style={props.bodyStyles}>
        {children}
      </div>
    </div>
  )
}

export default HomePageComponentDesktop;