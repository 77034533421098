export const ADD_EDIT_ACTIVITY = "ADD_EDIT_ACTIVITY";
export const ADD_EDIT_ACTIVITY_SUCCESS = "ADD_EDIT_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_BY_ID = "GET_ACTIVITY_BY_ID";
export const GET_ACTIVITY_BY_ID_SUCCESS = "GET_ACTIVITY_BY_ID_SUCCESS";
export const SORT_MY_ACTIVITIES = "SORT_MY_ACTIVITIES";
export const SORT_MY_ACTIVITIES_SUCCESS = "SORT_MY_ACTIVITIES_SUCCESS";
export const SORT_MY_ACTIVITIES_FAILURE = "SORT_MY_ACTIVITIES_FAILURE";
export const GET_EVENT_FEED = "GET_EVENT_FEED";
export const GET_EVENT_FEED_SUCCESS = "GET_EVENT_FEED_SUCCESS";
export const GET_EVENT_FEED_FAILURE = "GET_EVENT_FEED_FAILURE";
export const GET_EVENT_FEED_LIST = "GET_EVENT_FEED_LIST";
export const GET_EVENT_FEED_LIST_SUCCESS = "GET_EVENT_FEED_LIST_SUCCESS";
export const GET_EVENT_FEED_LIST_FAILURE = "GET_EVENT_FEED_LIST_FAILURE";

export function addEditActivity(data) {
    return {
        payload: data,
        type: ADD_EDIT_ACTIVITY,
    };
}

export function addEditActivitySuccess(data) {
    return {
        payload: data,
        type: ADD_EDIT_ACTIVITY_SUCCESS,
    };
}

export function getActivityById(data) {
    return {
        payload: data,
        type: GET_ACTIVITY_BY_ID,
    };
}

export function getActivityByIdSuccess(data) {
    return {
        payload: data,
        type: GET_ACTIVITY_BY_ID_SUCCESS,
    };
}

export function sortMyActivities(data) {
  return {
      payload: data,
      type: SORT_MY_ACTIVITIES,
  };
}

export function sortMyActivitiesSuccess(data) {
  return {
      payload: data,
      type: SORT_MY_ACTIVITIES_SUCCESS,
  };
}

export function sortMyActivitiesFailure(data) {
  return {
      payload: data,
      type: SORT_MY_ACTIVITIES_FAILURE,
  };
}

export function getEventFeed(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED,
    };
}

export function getEventFeedSuccess(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED_SUCCESS,
    };
}
export function getEventFeedFailure(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED_FAILURE,
    };
}

export function getEventFeedList(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED_LIST,
    };
}

export function getEventFeedListSuccess(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED_LIST_SUCCESS,
    };
}
export function getEventFeedListFailure(data) {
    return {
        payload: data,
        type: GET_EVENT_FEED_LIST_FAILURE,
    };
}
