import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import AutoSuggest from 'react-autosuggest';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as FuzzySearchActions from "../../store/actions/FuzzySearch.actions";
import './FuzzySearch.scss';
import debounce from 'lodash.debounce';
import aeroConfig from "../../config/aeroConfig";

const FuzzySearch = props => {
  const { fuzzySearchActions, history, onSelectSuggestion } = props;
  const [fuzzySuggestions, setFuzzySuggestions] = useState([]);
  const [fuzzyFilter, setFuzzyFilter] = useState('');
  const [activeItem, setActiveItem] = useState('company');
  const [showButtonPanel, setShowButtonPanel] = useState(false);
  const { searchResults, user } = props;
  const entity = new URLSearchParams(window.location.search).get('entity');
  const [currentRegion, setCurrentRegion] = useState(user && user.defaultFilter || 'Americas');

  const getRegion = (region, searchType) => {
    if (searchType === 'company') {
      switch (region) {
        case 'Americas':
          return 'United States,Canada';
        case 'Global':
          return null;
        default:
          return region;
      }
    } else {
      switch (region) {
        case 'United States':
          return 'UnitedStates';
        default:
          return region;
      }
    }
  };

  React.useEffect(() => {
    if (searchResults?.results) {
      setCurrentRegion(searchResults?.region);
      if (searchResults?.results.length > 0 && fuzzyFilter.length > 1) {
        let suggestions = searchResults?.results.map(item => {
          return {
            name: item.companyName || item.fullName || item.buildingName,
            label: item.companyName || item.fullName || item.buildingName,
            value: item.companyName || item.fullName || item.buildingName,
            type: activeItem.includes('company') ? 'companies' : (activeItem.includes('contact') ? 'contacts' : 'properties'),
            id: activeItem.includes('company') ? item.companyId : item.id,
          }
        })
        setFuzzySuggestions(suggestions);
      }
    }
    else {
      if (searchResults?.length > 0 && fuzzyFilter.length > 1) {
        setCurrentRegion(searchResults?.region);
        let suggestions = searchResults?.map(item => {
          return {
            name: item.companyName || item.fullName || item.buildingName,
            label: item.companyName || item.fullName || item.buildingName || item.address1,
            value: item.companyName || item.fullName || item.buildingName,
            type: activeItem.includes('company') ? 'companies' : (activeItem.includes('contact') ? 'contacts' : 'properties'),
            id: item.id,
          }
        })
        setFuzzySuggestions(suggestions);
      }
    }
  }, [searchResults]);

  const getSearchResults = useMemo(() => debounce(value => {
    fuzzySearchActions.getFuzzySearchResults(value);
  }, 600), []);

  const getFuzzySuggestions = (value, type = 'company') => {
    if (!isEmpty(value) && value.length >= 2) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      let typeVal = entity || type;
      if ((entity && entity.toLowerCase()) !== type.toLowerCase()) {
        typeVal = type;
      }

      const region = getRegion(currentRegion, typeVal);
      let regionState = null;
      let latestRegion = region;
      if (props.search) {
        regionState = (props.search.filterBy && props.search.filterBy.label) ? getRegion(props.search.filterBy.label, typeVal) : getRegion(props.search.filterBy, typeVal);
      }

      if (region != regionState) {
        latestRegion = regionState;
      }
      getSearchResults({ query: inputValue, limit: 10, skip: 0, type, isHeader: true, region: latestRegion });

      return inputLength === 0 && !isEmpty(fuzzySuggestions)
        ? []
        : fuzzySuggestions?.filter(o => {
          return (
            o && o.name && o.name.toLowerCase().slice(0, inputLength) === inputValue
          );
        });
    }
    return [];
  };

  const renderInputComponent = inputProps => (
    <div>
      <input placeholder={'Search companies, properties or contacts'} {...inputProps} />
    </div>
  );
  const onFuzzySuggestionsFetchRequested = ({ value }) => {
    if (value && value.length > 1) {
      setShowButtonPanel(true);
      const suggestions = getFuzzySuggestions(value, activeItem);
      setFuzzySuggestions(suggestions);
    } else {
      const list = localStorage.getItem('persist:root') && JSON.parse(localStorage.getItem('persist:root'));
      const searchList = list?.searchList ? JSON.parse(list?.searchList)?.map(item => {
        return {
          label: item.name,
          value: item.name,
          id: item.id,
          type: item.type === 'Company' ? 'companies' : (item.type === 'Contact' ? 'contacts' : 'properties'),
        };
      }) : [];
      setFuzzySuggestions(searchList);
    }
  };

  const onFuzzyChange = (event, { newValue }) => {
    setFuzzyFilter(newValue || '');
  };

  const onFuzzySuggestionsClearRequested = () => {
    setShowButtonPanel(false);
    setFuzzySuggestions([]);
    setFuzzyFilter('');
  };

  const onFuzzySuggestionSelected = (event, { suggestion }) => {
    if (event.keyCode !== 13) {
      if (suggestion.id > 0 || suggestion.id === -2) {
        onSelectSuggestion(suggestion);
        onFuzzySuggestionsClearRequested();
      } else {
        document.querySelector('[role="option"]').style.pointerEvents = 'none';
      }
    }
    event.stopPropagation();
    // handleSearch(suggestion.userName, suggestion.userId);
  };

  const buttonTypes = [{
    id: 1,
    name: 'Companies',
    value: 'company',
  },
  {
    id: 2,
    name: 'Contacts',
    value: 'contact',
  },
  {
    id: 3,
    name: 'Properties',
    value: 'property',
  }
  ];

  const getFuzzySuggestionValue = suggestion => suggestion.userName;

  const renderFuzzySuggestionContainer = ({ containerProps, children }) => {
    return (
      <div
        {...containerProps}
        className='auto-suggest-search-container'
      >
        {fuzzyFilter.length > 1 && showButtonPanel && <div className="buttonsContainer">
          {buttonTypes.map(item => {
            return <div className={classnames('fuzzySearchButtonClass', (activeItem === item.value) && 'selectedButtonClass')}
            onClick={(e) => {
              setActiveItem(item.value);
              getFuzzySuggestions(fuzzyFilter, item.value);
            }}>
              {item.name}
            </div>
          })}
        </div>}
        {fuzzySuggestions.length > 0 && fuzzyFilter.length < 2 && (
          <div className="recentLabel"> Recently Visited </div>
        )}
        {children}
        {(fuzzyFilter.length > 1 && showButtonPanel &&
          fuzzySuggestions.length >= 1 && fuzzySuggestions[0].id > 1) &&
          <div
            className="suggestionsFooter"
            onClick={() => { onFuzzySuggestionsClearRequested(); { window.open((`${aeroConfig.legacy_aero_uri}/search/${encodeURIComponent(fuzzyFilter)}?entity=${activeItem}`, '_self')) } }}
          >
            See all results
          </div>}
      </div>
    );
  };

  const shouldRenderFuzzySuggestions = () => {
    return true;
  }

  const renderFuzzySuggestion = suggestion => (
    <div
      className={suggestion.id > 0 ? "fuzzySuggest" : "fuzzySuggestNoResult"}
    >
      {suggestion.id === -2 ? <div>No matching results found. <u style={{ textTransform: 'capitalize'}}>{`create ${activeItem}`}</u ></div> : suggestion.label}
    </div>
  );

  const inputProps = {
    placeholder: 'Search companies, properties or contacts',
    value: fuzzyFilter,
    onChange: onFuzzyChange,
    onKeyDown: (e) => {
      if (e.keyCode === 13) {
        window.open(`${aeroConfig.legacy_aero_uri}/search/${encodeURIComponent(fuzzyFilter)}?entity=${activeItem}`, '_self');
        onFuzzySuggestionsClearRequested();
        e.currentTarget.blur();
      }
    }
  };

  return (
    <div className="fuzzySearchContainer">
      <AutoSuggest
        theme={{
          input: classnames(
            'input-searchinput-grey-magnifying-glass',
            'auto-suggest-fuzzy-search-input',
            fuzzyFilter.length > 0 && 'auto-suggest-fuzzy-search-Active',
          ),
          suggestionsContainer: 'auto-suggest-search-container',
          suggestion: 'auto-suggest-suggestion',
          suggestionHighlighted: 'suggestion-highlighted',
        }}
        renderInputComponent={renderInputComponent}
        style={{ border: '1px solid green !important' }}
        suggestions={fuzzySuggestions}
        onSuggestionsFetchRequested={onFuzzySuggestionsFetchRequested}
        onSuggestionsClearRequested={onFuzzySuggestionsClearRequested}
        getSuggestionValue={getFuzzySuggestionValue}
        renderSuggestion={renderFuzzySuggestion}
        onSuggestionSelected={onFuzzySuggestionSelected}
        renderSuggestionsContainer={renderFuzzySuggestionContainer}
        inputProps={inputProps}
        shouldRenderSuggestions={shouldRenderFuzzySuggestions}
        blurOnSelect
        focusInputOnSuggestionClick={false}
        className="selectInput"
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    searchResults: state.fuzzySearch.searchResults,
    search: state.search,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fuzzySearchActions: bindActionCreators(FuzzySearchActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuzzySearch);
