import api from "../../api/index";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
    LOOKUP,
    lookupSuccess,
} from "../actions/Lookup.actions";


export function* lookupSaga(action) {
    try {
        const { endpoint, params } = action.payload;
        const queryString = new URLSearchParams(params).toString();
        const response = yield call(
            api.lookupApi.lookup,
            `${endpoint}?${queryString}`
        );
        yield put(lookupSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* watchLookup() {
    yield takeEvery(LOOKUP, lookupSaga);
}

export default function* usersSagas() {
    yield all([
        watchLookup()
    ]);
}