import { BASE_URL, AuthHeaders } from "./config";
import queryString from 'query-string';
import axios from "axios";

export const getFuzzySearchResults = async ({ query, limit, skip, type, region }) => {
  const filterObj = {
    limit,
    query,
    skip,
  };

  if (region) {
    filterObj.region = region;
  }

  let filter = queryString.stringify(filterObj);

  if (type.includes('company')) {
    const response = await axios.get(
      `${BASE_URL}/newsearch/getCompanies?${filter}`,
      {
        headers: {
          ...AuthHeaders()
        }
      }
    );
    return response.data;
  }
  filter = queryString.stringify({
    limit,
    query,
    offset: skip,
    narrowByTerm: null,
    sortBy: '-relevance',
    region: region || 'unitedStates',
    type,
  });
  const response = await axios.get(
    `${BASE_URL}/search?${filter}`,
    {
      headers: {
        ...AuthHeaders()
      }
    }
  );
  return response.data;
};
