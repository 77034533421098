import { BASE_URL, AuthHeaders } from "./config";
import axios from "axios";
import queryString from 'query-string';

export const getExpiringLeases = async (data) => {
  const response = await axios.get(
    `${BASE_URL}/leases/expiring?${queryString.stringify(data)}`,
    {
      headers: {
        ...AuthHeaders()
      }
    }
  );
  return response.data;
}