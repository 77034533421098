export const GET_EXPIRING_LEASES = "GET_EXPIRING_LEASES";
export const GET_EXPIRING_LEASES_SUCCESS = "GET_EXPIRING_LEASES_SUCCESS";
export const GET_EXPIRING_LEASES_FAILURE = "GET_EXPIRING_LEASES_FAILURE";


export function getExpiringLeases(data) {
    return {
        payload: data,
        type: GET_EXPIRING_LEASES,
    };
}

export function getExpiringLeasesSuccess(data) {
    return {
        payload: data,
        type: GET_EXPIRING_LEASES_SUCCESS,
    };
}

export function getExpiringLeasesFailure(data) {
    return {
        payload: data,
        type: GET_EXPIRING_LEASES_FAILURE,
    };
}