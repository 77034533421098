import React from 'react';
import './EventFeedGrid.css';
import {Drawer} from "rsuite";
import {SolIcon} from "@solstice/sol-react";
import AeroGrid from "../AeroGrid/AeroGrid";
import {useSelector} from "react-redux";
import {dateFormat} from "../../../helpers/homepageHelper";

const EventFeedGrid = (props) => {

    const {activityActions} = props;
    React.useEffect(() => {
        activityActions.getEventFeedList({
            limit: 25,
            skip: 0,
            orderBycolumn: 'CreatedOn',
            sortDirection: 'DESC',
            refreshList: true
        });
    }, []);

    const [activePage, setActivePage] = React.useState(1);

    const handlePagination = (page, column, sort) => {
        setActivePage(page);
        activityActions.getEventFeedList({
            limit: 25,
            skip: (page - 1) * 25,
            orderBycolumn: column || 'CreatedOn',
            sortDirection: sort || 'DESC',
            refreshList: true
        });
    }
    const eventFeed = useSelector(state => state.activity.eventFeedList);

    const {isOpen, onClose} = props;
    let eventGridColumns = [{
        id: 1,
        label: 'Company',
        value: 'companyName',
        isSortable: true,
    },
        {
            id: 2,
            label: 'Team',
            value: 'contacts',
        },
        {
            id: 3,
            label: 'Lead market',
            value: 'city',
            isSortable: true,
        },
        {
            id: 4,
            label: 'Date of activity',
            value: 'dueDate',
            isSortable: true,
            hasTooltip: true,
            renderTooltip: (data) => {
                return(<div>
                    <div className={'toolTipContainer'}>
                        Date Created
                        <div className={'toolTipFooter'}>{dateFormat(data.createdOn, 'll')}</div>
                    </div>

                    <div className={'toolTipContainer'}>
                         Date Modified
                        <div className={'toolTipFooter'}>{data.modifiedOn ? dateFormat(data.modifiedOn, 'll'): '-'}</div>
                    </div>
                </div>)
            }
        }];

    const handleSort = (column, direction) => {
        setActivePage(1);
        activityActions.getEventFeedList({
            limit: 25,
            skip: 0,
            orderBycolumn: column,
            sortDirection: direction === '+'? 'ASC': 'DESC',
            refreshList: true
        });
    }
    return (
        <div className='aeroGridContainer'>
            <Drawer className='drawerPanel' size={'lg'} open={isOpen}>
                <div className='drawerContainer'>
                    <div className='drawerHeaderContainer'>
                        <div className='drawerHeader'>Event feed</div>
                        <SolIcon icon='close' className='drawerCloseIcon' onClick={() => onClose()}/>
                    </div>
                    <div>
                        <AeroGrid
                            activityActions={activityActions}
                            columns={eventGridColumns}
                            rowData={eventFeed?.events}
                            handlePagination={handlePagination}
                            total={eventFeed?.totalCount}
                            limit={25}
                            handleSort={handleSort}
                            activePage={activePage}
                        />
                    </div>
                </div>

            </Drawer>

        </div>
    )
};

export default EventFeedGrid;