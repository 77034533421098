export const COMPANY = '/companies/:companyId';
export const CONTACT = '/contacts/:contactId';
export const LANDING = '/';
export const LEASE = '/leases/:leaseId';
export const PROPERTY = '/properties/:propertyId';

export const OPPORTUNITIES_MANAGER = process.env
  .REACT_APP_OPPORTUNITY_MANAGER_URL
  ? process.env.REACT_APP_OPPORTUNITY_MANAGER_URL
  : (window.jllConfig || {}).opportunity_manager_url;

export const RAPSHEET_SUBSCRIPTION = process.env.REACT_APP_RAPSHEET_SUBSCRIPTION
  ? process.env.REACT_APP_RAPSHEET_SUBSCRIPTION
  : (window.jllConfig || {}).rapsheet_subscription_url;
