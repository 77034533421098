import React from 'react';
import { SolIcon } from "@solstice/sol-react";
import toDoListEmpty from '../../../assets/toDoListEmpty.svg';
import './ToDoList.css';
import { dateFormat } from "../../../helpers/homepageHelper";

const ToDoList = (props) => {
  const { toDoList } = props;
  const [toDoListPreview] = React.useState([...toDoList.slice(0, 2)]);

  return (
    <div className='toDoList' id='toDoList'>
      {toDoListPreview?.length === 0 ?
        <div className='toDoListEmpty'>
          <img style={{ paddingBottom: '35px' }} src={toDoListEmpty} />
          <br />
          <text>Your list is clear. Create activities to stay organized.</text>
        </div> :
        toDoListPreview?.map((activity, index) => {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className={index === 1 && 'toDoListSecondItem'}>
              <div>
                <div className='todoListConatiner'>
                  <span className='todoListDisplayName'>
                    {activity.regarding.displayName}
                  </span>
                  <span className='toDoListDate'>
                    {dateFormat(activity.createdOn, 'll')}
                  </span>
                </div>
                <div>
                  {activity.subject}
                </div>
              </div>
              <SolIcon slot="icon" icon="chevron_right" className='toDoListChevron' />
            </div>
          )
        })}
    </div>
  )
};

export default ToDoList;