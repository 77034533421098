export const ALL = 'all';
export const COMPANIES_TYPE = 'companies';
export const CONTACTS_TYPE = 'contacts';
export const PROPERTIES_TYPE = 'properties';

export const COMPANY_TYPE = 'company';
export const CONTACT_TYPE = 'contact';
export const PROPERTY_TYPE = 'property';
export const OPPORTUNITY_TYPE = 'opportunity';
export const LEASE_TYPE = 'lease';
export const LISTING = 'listing';

export default {
  ALL,
  COMPANIES_TYPE,
  CONTACTS_TYPE,
  PROPERTIES_TYPE,
  COMPANY_TYPE,
  CONTACT_TYPE,
  PROPERTY_TYPE,
  LEASE_TYPE,
  LISTING,
};
