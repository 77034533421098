import React from 'react';
import moment from "moment";
import leaseExpirationsEmpty from '../../../assets/leaseExpirationsEmpty.svg';
import './LeaseExpirations.css';
import aeroConfig from "../../../config/aeroConfig";

const LeaseExpirations = (props) => {
  const { leaseExpirations } = props;
  const [leaseExpirationsPreview] = React.useState([...leaseExpirations.slice(0, 4)]);

  return (
    <div className='leaseExpirations' id='leaseExpirations'>
      {leaseExpirationsPreview?.length === 0 ?
        <div className='leaseExpirationsEmpty'>
          <img style={{ width:'110px', paddingBottom: '10px' }} src={leaseExpirationsEmpty} />
          <br />
          <text>Close deals in Dealio to start tracking lease expirations here</text>
        </div> :
        leaseExpirationsPreview?.map((lease) => {
          return (
            <div style={{ paddingBottom: '15px' }}>
              <div className='leaseExpirationsTopText'>
                <a className='leaseExpirationCompanyName' onClick={ () => window.open(`${aeroConfig.legacy_aero_uri}/company/${lease?.company?.id}`, '_self')}  >
                  {lease.clientName}
                </a>
                <text className='leaseExpirationsDate'>
                  {moment(lease.endDate).format('ll')}
                </text>
              </div>
                {lease.address}
            </div>
          )
        })}
    </div>
  )
};

export default LeaseExpirations;