import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import './ContactDialog.css';
import * as ContactActions from "../../store/actions/Contact.actions";
import * as SearchActions from '../../store/actions/Search.actions';
import * as LookupActions from '../../store/actions/Lookup.actions';
import { SolDialog, SolDialogHeader, SolIcon, SolTextField, SolDropdown} from '@solstice/sol-react';
import Autosuggest from 'react-autosuggest';
import PhoneInput  from 'react-phone-input-2';
import { debounce } from 'lodash';
import 'react-phone-input-2/lib/style.css';
import getAutoCompleteAddressOptions from '../../helpers/google/getAutoCompleteAddressOptions';
import getAddress from '../../helpers/google/getAddress';
import countriesUtils from '../../helpers/countries';
import statesUtils from '../../helpers/states';
import {autoSuggestScrollToElement} from "../../utils/helpers";
import { setGA4Data } from "../../utils/googleAnalytics";
import {Oval} from "react-loader-spinner";

const ContactDialogMobile = (props) => {
    const {SetExitModal, contact, search, countries, lookupActions, searchActions, closeContactModalBtnClicked, regarding, contactActions, contactEditId} = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState(regarding?.companyName || regarding?.company?.displayName || '');
    const [companyId, setCompanyId] = useState(regarding?.id || regarding?.company.id || '');
    const [jobTitle, setJobTitle] = useState('');
    const [addressLine1, setAddressLine1] = useState(regarding?.addressLine1 || '');
    const [addressLine2, setAddress2] = useState(regarding?.addressLine2 || '');
    const [city, setCity] = useState(regarding?.city || '');
    const [zipCode, setZipCode] = useState(regarding?.zipCode || '');
    const [country, setCountry] = useState(regarding?.country?.displayName || regarding?.countryName || '');
    const [countryId, setCountryId] = useState(0);
    const [state, setState] = useState(statesUtils.getStateLongName(regarding?.state, regarding?.country.displayName || regarding?.countryName) || '');
    const [officePhone, setOfficePhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [importantInformation, setImportantInformation] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [companySuggestions, setCompanySuggestions] = useState([]);
    const [initialCompanyLoad, setInitialCompanyLoad] = useState(true);

    const contactData = {firstName, lastName, email, title: jobTitle,
        addressLine1, addressLine2, city, country, state: state?.split('-')[0].trim(), zipCode,
        officePhone, mobilePhone, importantInformation, companyId, countryId,
        id: contactEditId,
    }


    useEffect(() => {
        if (contactEditId !== null) {
            contactActions.getContactDetails({id: contactEditId, type: 'c' })
        }
    }, [contactEditId]);
    
    useEffect(() => {
        !countries && lookupActions.lookup('countries');
        document.getElementById('companyInput').blur();
        document.querySelector('[label="First Name"]')
    }, []);

    useEffect(() => {
        setCountryId(countries?.find(a => a.name === regarding?.country?.displayName)?.id || '');
    }, [countries]);

    useEffect(() => {
        if(initialCompanyLoad === false) {
            setCompanySuggestions(search);
        }
    }, [search]);

    const getCompaniesDebounced = useCallback(
        debounce((company) => {props.searchActions.getSearchDetails({
            type: 'companies',
            searchText: company,
            pageNo: 1,
            offset: 0,
        })
        setInitialCompanyLoad(false);
    }, 300), []
    );

    const getAddressDebounced = useCallback(
        debounce((addressLine1, country) => {getAutoCompleteAddressOptions(addressLine1, country)
            .then(results => {
                setAddressSuggestions(results);
            })
            .catch(err => {
                if (err === 'ZERO_RESULTS') {
                    setAddressSuggestions([]);
                }
        })}, 300), []
    )

    useEffect(() => {
        !companyId && getCompaniesDebounced(company);

        },
    [company]);

    useEffect(() => {
        !countryId && getAddressDebounced(addressLine1, country);
    }, [addressLine1]);

    useEffect(() => {
        if (contactEditId !== null) {
        setFirstName(contact.firstName);
        setLastName(contact.lastName);
        setEmail(contact.email);
        setCompany(contact.company.displayName);
        setCompanyId(contact.company.id);
        setJobTitle(contact.jobTitle);
        setAddressLine1(contact.addressLine1);
        setAddress2(contact.addressLine2);
        setCity(contact.city);
        setState(statesUtils.getStateLongName(contact.state, contact.country.displayName));
        setZipCode(contact.zipCode);
        setCountry(contact.country.displayName);
        setCountryId(contact.country.id);
        setOfficePhone(contact.officePhone || '');
        setMobilePhone(contact.mobilePhone || '');
        setImportantInformation(contact.importantInformation);
    }
    }, [contact]);

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const field = (value, label, onChange, optional, placeholder) =>
    <div>
        <SolTextField
            value={value}
            onSol-input={e => onChange(e.detail)}
            label={label}
            required={optional ? "false" : "true"}
            placeholder={placeholder}
        />
        {label==='Address Line 2' && <SolIcon className='addressLineIcon' slot="icon" icon="location_on" />}
        <div className="fieldSpacer" />
    </div>;

    const handleAddEditContact = async (activityText) => {
        const { regarding } = props;
        if (activityText === 'Save') {
            setGA4Data('ContactModified', { companyID: regarding?.id });
        } else {
            setGA4Data('ContactCreated', { companyID: regarding?.id });
        }
        contactActions.addEditContact({contactData, cb: (flag) =>  {
                setShowLoader(flag);
                if(!flag) {
                    closeContactModalBtnClicked();
                }
            }});
    }
    const onModalClose = () => {
        SetExitModal(true);
    }
    const validateForm = () => {
        if (firstName.length < 2 || lastName.length < 2 || !companyId || !addressLine1 || !city || !countryId) {
            return false
        }
        else {
            return true;
        }
    }

    const onAddressSuggestionSelected = (event, { suggestion }) => {
        event.preventDefault();
        onAddressSuggestionsClearRequested();
        setAddressLine1(suggestion.structured_formatting.main_text);
        setCity(suggestion.terms[2]?.value);
        setState(statesUtils.getStateLongName(suggestion.terms[3]?.value, countriesUtils.getCountryLongName(suggestion.terms[suggestion.terms.length-1]?.value)) );
        getAddress(suggestion.place_id).then(results => {
            setZipCode(results.addressPostalCode);
        })
        setCountry(countriesUtils.getCountryLongName(suggestion.terms[suggestion.terms.length-1]?.value));
        setCountryId(countries?.find(a => a.name === countriesUtils.getCountryLongName(suggestion.terms[suggestion.terms.length-1]?.value))?.id);
    }
    const onAddressSuggestionsClearRequested = () => {
        setAddressSuggestions([]);
    };
    const onAddressSuggestionsFetchRequested = () => {
        return addressSuggestions;
    };
    const onAddressChange = (val) => {
        setAddressLine1(val.target?.value);
        setCountryId(null);
    };
    const getAddressSuggestionValue = suggestion => suggestion.description;
    const renderAddressSuggestion = suggestion => (
        <div>
            {suggestion.description}
        </div>
    );

    const onCompanySuggestionSelected = (event, { suggestion }) => {
        event.preventDefault();
        setCompany(suggestion.companyName);
        setCompanyId(suggestion.id);
        onCompanySuggestionsClearRequested();
    };
    const onCompanySuggestionsClearRequested = () => {
        setCompanySuggestions([]);
    };
    const onCompanySuggestionsFetchRequested = () => {
        return companySuggestions;
    };
    const onCompanyChange = (val) => {
        setCompany(val.target?.value);
        setCompanyId(null);
    };
    const getCompanySuggestionValue = suggestion => suggestion.description;
    const renderCompanySuggestion = suggestion => (
        <div>
            {suggestion.companyName}
        </div>
    );

    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
        </div>
    );

    return (
        <div className='ContactDialog' id='ContactDialog'>
            <div className='sol-dialog__backdrop'/>
            {showLoader && <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}> <Oval
                visible={true}
                height="40"
                width="40"
                color="#000000"
                secondaryColor={"gray"}
                ariaLabel="oval-loading"
                wrapperStyle={{zIndex: 1000000}}
                wrapperClass=""
            />
            </div>}
            {!showLoader &&
            <SolDialog
                hasBackdrop={false}
                className="contactModal"
                floatingPosition="center"
                onSol-close={() => { onModalClose()}}
            >
                <SolDialogHeader
                    slot="header"
                    label={contactEditId !== null ? "Edit Contact" : "Add Contact"}
                >
                    <SolIcon slot="icon" icon={contactEditId !== null ? 'edit' : 'add_circle'} />
                </SolDialogHeader>
                <div slot="body" id='dialogBody'>
                    {field(firstName, "First Name", setFirstName)}
                    {field(lastName, "Last Name", setLastName)}
                    {field(email, "Email Address", setEmail, true)}
                    <label className='frmLabel' id="company">Company <span className='requiredStar'>*</span></label>
                    <Autosuggest
                        required
                        theme={{
                            input: "auto-suggest-input",
                            suggestionsContainer: 'auto-suggest-container',
                            suggestion: 'auto-suggest-suggestion',
                            suggestionHighlighted: 'suggestion-highlighted'
                        }}
                        style={{border: '1px solid green !important'}}
                        suggestions={companySuggestions || []} 
                        onSuggestionsFetchRequested={onCompanySuggestionsFetchRequested}
                        onSuggestionsClearRequested={onCompanySuggestionsClearRequested}
                        getSuggestionValue={getCompanySuggestionValue}
                        renderSuggestion={renderCompanySuggestion}
                        onSuggestionSelected={onCompanySuggestionSelected}
                        renderInputComponent={renderInputComponent}
                        inputProps={{
                            id: 'companyInput',
                            value: company || '',
                            onChange: onCompanyChange,
                            onClick: () => {
                                autoSuggestScrollToElement('company');
                            }
                            }}
                        blurOnSelect
                        class="selectInput"
                        className="selectInput"
                    />
                    <div className="fieldSpacer" />
                    {field(jobTitle, "Job Title", setJobTitle, true)}
                    <div className='addressBox'>
                        Address
                        <div className="fieldSpacer" />
                        <label className='frmLabel' id='addressLine1'>Address Line 1 <span className='requiredStar'>*</span></label>
                        <div>
                        <Autosuggest
                            theme={{
                                input: "auto-suggest-input",
                                suggestionsContainer: 'auto-suggest-container',
                                suggestion: 'auto-suggest-suggestion',
                                suggestionHighlighted: 'suggestion-highlighted'
                            }}
                            style={{border: '1px solid green !important'}}
                            suggestions={addressSuggestions}
                            onSuggestionsFetchRequested={onAddressSuggestionsFetchRequested}
                            onSuggestionsClearRequested={onAddressSuggestionsClearRequested}
                            getSuggestionValue={getAddressSuggestionValue}
                            renderSuggestion={renderAddressSuggestion}
                            onSuggestionSelected={onAddressSuggestionSelected}
                            inputProps={{
                                value: addressLine1 || '',
                                onChange: onAddressChange,
                                onClick: () => {
                                    autoSuggestScrollToElement('addressLine1');
                                }
                            }}
                            blurOnSelect
                            class="selectInput"
                            className="selectInput"
                        />
                        <SolIcon className='addressLineIcon' slot="icon" icon="location_on" />
                        </div>
                        <div className="fieldSpacer" />
                        {field(addressLine2, "Address Line 2", setAddress2, true, "Enter address")}
                        {field(city, "City", setCity)}
                        {countries && <SolDropdown
                            style={{ position: 'static' }}
                            required
                            label="Country"
                            value={country === 'USA' ? 'United States' : country}
                            options={countries?.map(a => {
                                return { label: a.name, value: a.name }
                            })}
                            onUpdate-value={e => {setCountryId(countries?.find(a => a.name === e.detail).id); setCountry(e.detail); setState('');}}
                        />}
                        <SolDropdown
                            label="State"
                            value={state}
                            options={
                                (statesUtils.states?.find(a => a.name.toLowerCase() === country.toLowerCase())?.value)?.map(
                                    a => {return { label: a.name, value: a.name }})
                                || [{label: '-', value: ''}]
                            }
                            onUpdate-value={e => {setState(e.detail)}}
                        />
                        {field(zipCode, "Zip Code", setZipCode)}
                    </div>
                    <label className='frmLabel'>Business Phone</label>
                    <PhoneInput
                        flags={true}
                        containerClass=''
                        inputClass='sol-text-field'
                        country={country === 'United States' ? 'us' : countriesUtils.getCountryShortName(country).toLowerCase()}
                        value={officePhone}
                        placeholder=''
                        onChange={e => setOfficePhone(e)}
                        withCountryCallingCode={true}
                        preferredCountries={['ca', 'us']}
                        specialLabel=''
                        countryCodeEditable={false}
                    />
                    <div className="fieldSpacer" />
                    <label className='frmLabel'>Mobile Phone</label>
                    <PhoneInput
                        flags={true}
                        containerClass=''
                        inputClass='sol-text-field'
                        country={country === 'United States' ? 'us' : countriesUtils.getCountryShortName(country)}
                        value={mobilePhone}
                        placeholder=''
                        onChange={e => setMobilePhone(e)}
                        withCountryCallingCode={true}
                        preferredCountries={['ca', 'us']}
                        specialLabel=''
                        countryCodeEditable={false}
                    />
                    <div className="fieldSpacer" />
                    <label className='frmLabel'>Description</label>
                    <textarea id="importantInformation" name="importantInformation"
                        maxLength={2000}
                        placeholder='Enter the description'
                        value={importantInformation}
                        onChange={e => {setImportantInformation(e.target?.value)}}
                        rows="4" cols="46" className="descTextArea"
                    />
                    <div className='ContactFooterButton'
                        style={{
                            cursor: validateForm() ? 'pointer' : 'not-allowed',
                            background: validateForm() && '#01151C',
                            color: validateForm() && '#FFFFFF'
                        }}
                        onClick={() => {
                            if(validateForm()) {
                                handleAddEditContact(contactEditId !== null ? 'Save' : 'Create');
                            }
                        }}
                    >
                        <sol-icon icon={contactEditId !== null ? 'save' : 'check_circle' }
                            style={{
                                color: validateForm() ?  '#32A4CA' : '#01151C'
                            }}
                        />{`${contactEditId !== null ? 'Save' : 'Create'} Contact`}
                    </div>
                </div>
            </SolDialog>}
        </div>
    )
}

ContactDialogMobile.propTypes = {
    SetExitModal: PropTypes.func,
    contact: PropTypes.object,
    search: PropTypes.array,
    countries: PropTypes.array,
    lookupActions: PropTypes.object,
    searchActions: PropTypes.object,
    closeContactModalBtnClicked: PropTypes.func,
    regarding: PropTypes.object,
    contactActions: PropTypes.object,
    contactEditId: PropTypes.string,
};

ContactDialogMobile.defaultProps = {
    SetExitModal: () => {},
    contact: {},
    search: [],
    countries: [],
    lookupActions: {},
    searchActions: {},
    closeContactModalBtnClicked: () => {},
    regarding: {},
    contactActions: {},
    contactEditId: '',
};

function mapStateToProps(state) {
    return {
        contact: state.contact.contactDetails,
        search: state.search.searchResults.companiesResults,
        countries: state.lookup.lookup,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        contactActions: bindActionCreators(ContactActions, dispatch),
        searchActions: bindActionCreators(SearchActions, dispatch),
        lookupActions: bindActionCreators(LookupActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactDialogMobile);