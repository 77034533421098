import React, { useState } from 'react';
import { deleteLocalStorageValue, getUser } from "../../../utils/tokenManager";
import { useDispatch } from "react-redux";
import aeroConfig from "../../../config/aeroConfig";
import { getBlogReport } from "../../../store/actions/Echelon.actions";
import { SolIcon } from "@solstice/sol-react";
import brandIcon from "../../../assets/brand.png";
import './Header.css';

const HeaderDesktop = (props) => {
  
  const { searchParams } = props;
  const dispatch = useDispatch();
  const user = getUser();
  const oktalogout = aeroConfig.oktalogout || process.env.REACT_APP_OKTALOGOUT;
  const [showLogout, SetShowLogout] = React.useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const getReport = () => {
    if (!isDisable()) {
      dispatch(getBlogReport({
        searchData: searchParams,
        newSearch: false
      }));
    }
  }

  const isDisable = () => {
    const startDate = searchParams.StartDate;
    const endDate = searchParams.EndDate;
    if (!(startDate && endDate)) {
      return true;
    } else {
      return false;
    }
  };

  const getSubMarketsList = (entry) => {
    let submarketList = '';
    entry?.subMarkets?.map((submarket, index) => {
      submarketList = `${submarketList} ${submarket.displayName}`
    });
    return submarketList;
  }

  React.useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e?.target?.id?.includes('user') || e?.target?.parentElement?.id.includes('user') || e?.target?.id.includes('logoutContainer')) {
        SetShowLogout(!showLogout);
      } else {
        SetShowLogout(false);
      }
    });
  }, [showLogout]);

  return (
    <div className='echelonHeader' id='MainHeader'>
      <div>
        <img
          className='headerLogo'
          src={brandIcon}
          alt="Echelon"
        />
      </div>
      <div
        className='echelonUserSection'
        id='userSection'
        role="button"
      >
        <div
          className={isDisable() ? "tooltip-container-disabled" : "tooltip-container"}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div
            style={{ display: 'flex', cursor: !isDisable() ? 'pointer' : 'default' }}
            onClick={() => { getReport(); }}
          >
            <SolIcon icon='ios_share' className='exportIcon' />
            <div className={'exportText'}>Export</div>
          </div>
          {showTooltip && (
            <div class="tooltip-popup">{isDisable() ? 'Please select a date range to export.' : <><b>JLL Only</b> blogs are <b>not</b> included in the exports.</>}</div>
          )}
        </div>
        <div id="userCircle" style={{ display: "flex" }}>
          <div className='headerUserCircle'>
            {user?.[0]}
            {user?.split('.')[1][0]}
          </div>
          <div
            style={{ margin: '10px 10px 0 5px', color: '#FFF' }}
            className='sol-icon-keyboard_arrow_down'
          />
        </div>
        <a
          id={'logoutContainer'}
          href={oktalogout}
          onClick={() => {
            deleteLocalStorageValue('okta-token-storage');
          }}
          style={{ display: !showLogout && 'none' }}
        >
          <div className='LogoutTooltip'>
            Log out
            <div className='sol-icon-logout' />
            <div className="LogoutTooltipArrow" />
          </div>
        </a>
      </div>
    </div>
  )
}

export default HeaderDesktop;