import moment from "moment";
export const pills = {
    substantial: {
        id: 1,
        background: "#EAEEFC",
        color: "#2245B0",
        icon: 'vital_signs',
        text: 'Substantial',
    },
    closeDeal: {
        id: 1,
        background: "#00FF7714",
        color: '#046744',
        text: 'Close Deal',
        icon: 'signature'
    }
}

export const dateFormat = (date, format) => {
    return moment(date).format(format)

}