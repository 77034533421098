import React from 'react';
import 'rsuite/dist/rsuite.min.css';
import {Button, Modal} from "rsuite";
import {SolIcon} from "@solstice/sol-react";


const AeroModal = (props) => {

    const {
        title,
        handleClose,
        primaryButtonText,
        secondaryButtonText,
        handlePrimaryButtonClick,
        handleSecondaryButtonClick,
        isOpen,
        size
    } = props;

    return (
            <Modal
                overflow={false}
                size={size || 'sm'}
                backdrop={'static'}
                open={isOpen}
                onHide={() => {
                        handleClose();
                }}
            >
                <Modal.Header closeButton={false} style={{display: 'flex', justifyContent: 'space-between',}}>
                    <Modal.Title> {title}</Modal.Title>
                    <SolIcon onClick={() => handleClose()} icon={'close'} />
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'secondaryButton'} onClick={() => handleSecondaryButtonClick()}>
                        {secondaryButtonText}
                    </Button>
                    <Button className={'primaryButton'} onClick={() => handlePrimaryButtonClick()}>
                        {primaryButtonText}
                    </Button>

                </Modal.Footer>
            </Modal>
)
};

export default AeroModal;