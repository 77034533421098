import React from 'react';
import PropTypes from 'prop-types';
import './AeroHomePage.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ActivityActions from "../../store/actions/Activity.actions";
import * as LeaseActions from "../../store/actions/Lease.actions";
import GlobalHeader from "../../components/Aero/GlobalHeader/GlobalHeader";
import HomePageComponentDesktop from "../../components/Aero/HomePageComponent/HomePageComponentDesktop"
import ToDoList from "../../components/Aero/ToDoList/ToDoList";
import LeaseExpirations from "../../components/Aero/LeaseExpirations/LeaseExpirations";
import EventFeedContainer from "../../components/Aero/EventFeedContainer/EventFeedContainer";
import { Grid, Col, Row, Placeholder } from 'rsuite';
import LeaseExpirationsGrid from "../../components/Aero/LeaseExpirationsGrid/LeaseExpirationsGrid";
import ToDoListGrid from "../../components/Aero/ToDoListGrid/ToDoListGrid";

const AeroHomePage = (props) => {
  const { history, activityActions, leaseActions, activity, lease } = props;
  const [openTodoGrid, setOpenTodoGrid] = React.useState(false);
  const [openLeaseExpirationGrid, setOpenLeaseExpirationGrid] = React.useState(false);
  const [legacyUrl, setLegacyUrl] = React.useState('');

  React.useEffect(() => {
    activityActions.sortMyActivities({ limit: 10, offset: 0, direction: '-', sort: 'dueDate' });
    leaseActions.getExpiringLeases({ limit: 10, offset: 0 });
    activityActions.getEventFeed({ limit: 25, skip: 0, orderBycolumn: 'CreatedOn', sortDirection: 'DESC' });
  }, []);

  const handleUrlChange =(data) => {
      setLegacyUrl(data);
  }

  return (
    <div>
      <GlobalHeader history={history} handleLegacyUrl={handleUrlChange} />
        {!legacyUrl && <React.Fragment>
            <Grid fluid>
                <Row className='homePageBodyContainer' gutter={24}>
                    <Col xs={16}>
                        <EventFeedContainer activityActions={activityActions}/>
                    </Col>
                    <Col xs={8}>
                        <Row style={{height: '50% !important', overflow: 'hidden'}}>
                            <HomePageComponentDesktop
                                handleOnClick={() => {
                                    setOpenLeaseExpirationGrid(true);
                                }}
                                header="Lease expirations"
                                data={lease?.lease?.expiringLeases}
                                containerStyles={{height: 'calc(70vh - 300px)'}}
                            >
                                {lease?.lease?.expiringLeases ?
                                    <LeaseExpirations leaseExpirations={lease?.lease?.expiringLeases}/> : <div
                                        style={{minHeight: '30vh', paddingTop: '20px'}}>
                                        <Placeholder.Grid rows={3} columns={2} active/>
                                    </div>}
                            </HomePageComponentDesktop>
                        </Row>
                        <Row style={{height: '50% !important', overflow: 'hidden', marginTop: '20px'}}>
                            <HomePageComponentDesktop
                                handleOnClick={() => {
                                    setOpenTodoGrid(true);
                                }}
                                header="To do list"
                                data={activity?.toDoList?.activities}
                                containerStyles={{height: '30vh'}}
                            >
                                {activity?.toDoList ? <ToDoList toDoList={activity?.toDoList?.activities || []}/> :
                                    <div style={{minHeight: '30vh', paddingTop: '20px'}}>
                                        <Placeholder.Grid rows={3} columns={2} active/>
                                    </div>}
                            </HomePageComponentDesktop>
                        </Row>
                    </Col>
                </Row>
            </Grid>
            <LeaseExpirationsGrid isOpen={openLeaseExpirationGrid} data={lease?.lease} onClose={() => setOpenLeaseExpirationGrid(false)}/>
          <ToDoListGrid isOpen={openTodoGrid} data={activity?.toDoList} onClose={() => setOpenTodoGrid(false)} />
        </React.Fragment>}
        {legacyUrl && <div>
            <iframe
                style={{height: 'calc(100vh - 160px)'}}
                width='100%'
                src={legacyUrl}
            />
        </div>}
    </div>
  )
}

AeroHomePage.propTypes = {
  history: PropTypes.object,
  searchActions: PropTypes.object,
  searchDetails: PropTypes.object,
};

AeroHomePage.defaultProps = {
  history: {},
  searchActions: {},
  searchDetails: {},
};

function mapStateToProps(state) {
  return {
    searchDetails: state.search,
    activity: state.activity,
    lease: state.lease,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    activityActions: bindActionCreators(ActivityActions, dispatch),
    leaseActions: bindActionCreators(LeaseActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AeroHomePage);
